<script>
    import { onMount } from 'svelte';
    import { createEventDispatcher } from 'svelte';
    import Select from 'svelte-select';
    import RangeSlider from 'svelte-range-slider-pips';
    import MaskInput from 'svelte-input-mask/MaskInput.svelte';
    import { push } from 'svelte-spa-router';

    import Button from 'components/Button';
    import Lottie from 'components/Lottie';
    import DateSelector from 'components/DateSelector';
    import LoanCalculator from 'components/LoanCalculator';

    import SHARED_CONFIG from 'configs/shared';
    import REGISTER_CONFIG from 'configs/register';

    import { formatCurrency } from 'services/util';
    import registerStore from 'stores/register';

    const dispatch = createEventDispatcher();

    export let step = REGISTER_CONFIG.STEPS.ESSENTIALS;

    let isFormValid = true;
    let formAnchor;
    let formErrors = [];
    let formErrorsMap = {};
    let countryOptions = [];
    let yearOptions = [];
    let phoneMask = `000-000-0000`;
    let canReviewApplication = false;
    let isPasswordRevealed = false;

    let review = {
        essentials: false,
        loan: false,
        details: false,
        financials: false,
        employer: false,
        business: false,
        car: false,
        credentials: false,
    };

    onMount(() => {
        loadCountries();
        loadYearOptions();
    });

    $: {
        formErrors = [];
        formErrorsMap = {};
        isFormValid = true;

        switch (step.key) {
            case REGISTER_CONFIG.STEPS.DETAILS.key:
                break;
            case REGISTER_CONFIG.STEPS.INSURANCE.key:
                break;
            case REGISTER_CONFIG.STEPS.EMPLOYER.key:
                break;
            case REGISTER_CONFIG.STEPS.BUSINESS.key:
                break;
            case REGISTER_CONFIG.STEPS.CAR.key:
                break;
            case REGISTER_CONFIG.STEPS.REVIEW.key:
                canReviewApplication = true;
            default:
                break;
        }
    }

    function loadCountries() {
        countryOptions = REGISTER_CONFIG.OPTIONS.COUNTRY;
    }

    function loadYearOptions() {
        yearOptions = [];
        let minYear = 1930;
        let maxYear = 2025;

        for (let i = maxYear; i >= minYear; i--) {
            yearOptions.push({
                label: i,
                value: i,
            });

            yearOptions = yearOptions;
        }
    }

    function validate(step) {
        formErrors = [];
        formErrorsMap = {};

        switch (step.key) {
            case REGISTER_CONFIG.STEPS.ESSENTIALS.key:
                if (!$registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country) {
                    formErrors.push('Please select a Country from the list.');
                    formErrorsMap['country'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType) {
                    formErrors.push('Please select between a Personal Loan, SME Loan or Car Loan.');
                    formErrorsMap['loanType'] = true;
                }

                break;
            case REGISTER_CONFIG.STEPS.DETAILS.key:
                if (!$registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].firstName) {
                    formErrors.push('Please enter your first name.');
                    formErrorsMap['firstName'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].lastName) {
                    formErrors.push('Please enter your last name.');
                    formErrorsMap['lastName'] = true;
                }

                if (
                    !$registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].emailAddress ||
                    $registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].emailAddress !=
                        $registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].confirmEmailAddress
                ) {
                    formErrors.push("Please ensure you've confirmed your email address correctly.");
                    formErrorsMap['emailAddress'] = true;
                    formErrorsMap['confirmEmailAddress'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].dob.date) {
                    formErrors.push('Please enter your date of birth.');
                    formErrorsMap['dob'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].gender) {
                    formErrors.push('Please select your gender.');
                    formErrorsMap['gender'] = true;
                }

                if (
                    !$registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].phoneNumber.primary.full ||
                    $registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].phoneNumber.primary.full.length != 12
                ) {
                    formErrors.push('Please enter a valid phone number.');
                    formErrorsMap['phoneNumberPrimary'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].address.city) {
                    formErrors.push('Please enter a valid city.');
                    formErrorsMap['address.city'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].address.street) {
                    formErrors.push('Please enter a valid street.');
                    formErrorsMap['address.street'] = true;
                }

                break;
            case REGISTER_CONFIG.STEPS.FINANCIALS.key:
                if (!$registerStore[REGISTER_CONFIG.STEPS.FINANCIALS.key].bankName) {
                    formErrors.push('Please enter a valid bank name.');
                    formErrorsMap['bankName'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.FINANCIALS.key].bankAccount.number) {
                    formErrors.push('Please enter a valid bank account number.');
                    formErrorsMap['bankAccount.number'] = true;
                }

                if (isNaN($registerStore[REGISTER_CONFIG.STEPS.FINANCIALS.key].bankAccount.number)) {
                    formErrors.push('Please remove all non-numerical characters from your bank account number.');
                    formErrorsMap['bankAccount.number'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.FINANCIALS.key].bankAccount.type) {
                    formErrors.push('Please enter a valid bank account type.');
                    formErrorsMap['bankAccount.type'] = true;
                }
                break;
            case REGISTER_CONFIG.STEPS.EMPLOYER.key:
                if (!$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].name) {
                    formErrors.push('Please enter a valid employer name.');
                    formErrorsMap['name'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].occupation) {
                    formErrors.push('Please enter a valid occupation.');
                    formErrorsMap['occupation'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].address.city) {
                    formErrors.push('Please enter a valid city.');
                    formErrorsMap['address.city'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].address.street) {
                    formErrors.push('Please enter a valid street.');
                    formErrorsMap['address.street'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].address.country) {
                    formErrors.push('Please enter a valid country.');
                    formErrorsMap['address.country'] = true;
                }

                if (
                    !$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].income.amount &&
                    $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType != SHARED_CONFIG.LOAN_TYPES.CAR
                ) {
                    formErrors.push('Please enter a valid income.');
                    formErrorsMap['income.amount'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].hireDate) {
                    formErrors.push('Please enter a valid date of hire.');
                    formErrorsMap['hireDate'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].income.frequency.type) {
                    formErrors.push('Please enter a valid income type.');
                    formErrorsMap['income.frequency.type'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].income.frequency.dayOfWeek) {
                    formErrors.push('Please enter a valid day of the week for your payments.');
                    formErrorsMap['income.frequency.dayOfWeek'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].disclosures.credit.value) {
                    formErrors.push('Please agree to all disclosures before continuing.');
                    formErrorsMap['disclosures.credit'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].disclosures.loanStatus.value) {
                    formErrors.push('Please agree to all disclosures before continuing.');
                    formErrorsMap['disclosures.loanStatus'] = true;
                }

                break;
            case REGISTER_CONFIG.STEPS.BUSINESS.key:
                if (!$registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].name) {
                    formErrors.push('Please enter a valid business name.');
                    formErrorsMap['name'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].address.street) {
                    formErrors.push('Please enter a valid street.');
                    formErrorsMap['address.street'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].address.city) {
                    formErrors.push('Please enter a valid city.');
                    formErrorsMap['address.city'] = true;
                }

                if (
                    !$registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].phoneNumber.full ||
                    $registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].phoneNumber.full.length != 12
                ) {
                    formErrors.push('Please enter a valid phone number.');
                    formErrorsMap['phoneNumber'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].startDate.date) {
                    formErrors.push('Please enter a valid operations start date.');
                    formErrorsMap['startDate'] = true;
                }

                if ($registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].numEmployees.fullTime == null) {
                    formErrors.push('Please enter a valid number of full time employees.');
                    formErrorsMap['numEmployees.fullTime'] = true;
                }

                if ($registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].numEmployees.partTime == null) {
                    formErrors.push('Please enter a valid number of part time employees.');
                    formErrorsMap['numEmployees.partTime'] = true;
                }

                if ($registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].averageMonthlySalesRevenue == null) {
                    formErrors.push('Please enter a valid average monthly sales revenue.');
                    formErrorsMap['averageMonthlySalesRevenue'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].reasonForBorrowing) {
                    formErrors.push('Please tell us your reason for borrowing.');
                    formErrorsMap['reasonForBorrowing'] = true;
                }

                if (
                    !$registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].disclosures.transunion ||
                    ($registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].disclosures.transunion &&
                        $registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].disclosures.transunion.value != true)
                ) {
                    formErrors.push('Please agree to terms and conditions before continuing.');
                    formErrorsMap['disclosures.transunion'] = true;
                }

                break;
            case REGISTER_CONFIG.STEPS.CAR.key:
                if ($registerStore[REGISTER_CONFIG.STEPS.CAR.key].driversLicense == null) {
                    formErrors.push('Please tell us if you have a valid driver license.');
                    formErrorsMap['driversLicense'] = true;
                }

                if (
                    $registerStore[REGISTER_CONFIG.STEPS.CAR.key].vehicleDebt.hasDebt &&
                    $registerStore[REGISTER_CONFIG.STEPS.CAR.key].vehicleDebt.hasDebt.value
                ) {
                    if ($registerStore[REGISTER_CONFIG.STEPS.CAR.key].vehicleDebt.balance <= 0) {
                        formErrors.push('Please enter a vehicle debt balance greater than $0.');
                        formErrorsMap['vehicleDebt.balance'] = true;
                    }

                    if ($registerStore[REGISTER_CONFIG.STEPS.CAR.key].vehicleDebt.monthlyPayment <= 0) {
                        formErrors.push('Please enter a vehicle debt monthly payment greater than $0.');
                        formErrorsMap['vehicleDebt.monthlyPayment'] = true;
                    }
                }

                if (
                    $registerStore[REGISTER_CONFIG.STEPS.CAR.key].insurance.isValid &&
                    $registerStore[REGISTER_CONFIG.STEPS.CAR.key].insurance.isValid.value
                ) {
                    if (
                        $registerStore[REGISTER_CONFIG.STEPS.CAR.key].insurance.type &&
                        !$registerStore[REGISTER_CONFIG.STEPS.CAR.key].insurance.type.value
                    ) {
                        formErrors.push('Please enter a valid insurance type.');
                        formErrorsMap['insurance.type'] = true;
                    }

                    if (!$registerStore[REGISTER_CONFIG.STEPS.CAR.key].insurance.company) {
                        formErrors.push('Please enter a valid insurance company.');
                        formErrorsMap['insurance.company'] = true;
                    }
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.CAR.key].licensePlateNumber) {
                    formErrors.push('Please enter a valid license plate number.');
                    formErrorsMap['licensePlateNumber'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.CAR.key].vehicle.year) {
                    formErrors.push('Please enter a valid vehicle year.');
                    formErrorsMap['vehicle.year'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.CAR.key].vehicle.make) {
                    formErrors.push('Please enter a valid vehicle make.');
                    formErrorsMap['vehicle.make'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.CAR.key].vehicle.model) {
                    formErrors.push('Please enter a valid vehicle model.');
                    formErrorsMap['vehicle.model'] = true;
                }

                if (
                    !$registerStore[REGISTER_CONFIG.STEPS.CAR.key].disclosures.transunion ||
                    ($registerStore[REGISTER_CONFIG.STEPS.CAR.key].disclosures.transunion &&
                        $registerStore[REGISTER_CONFIG.STEPS.CAR.key].disclosures.transunion.value != true)
                ) {
                    formErrors.push('Please agree to terms and conditions before continuing.');
                    formErrorsMap['disclosures.transunion'] = true;
                }

                break;
            case REGISTER_CONFIG.STEPS.CREDENTIALS.key:
                if (!$registerStore[REGISTER_CONFIG.STEPS.CREDENTIALS.key].username) {
                    formErrors.push('Please enter a valid username.');
                    formErrorsMap['username'] = true;
                }

                if (
                    $registerStore[REGISTER_CONFIG.STEPS.CREDENTIALS.key].username &&
                    $registerStore[REGISTER_CONFIG.STEPS.CREDENTIALS.key].username.length <= 4
                ) {
                    formErrors.push('Please make sure your username is longer than 4 characters.');
                    formErrorsMap['username'] = true;
                }

                if (!$registerStore[REGISTER_CONFIG.STEPS.CREDENTIALS.key].password) {
                    formErrors.push('Please enter a valid password.');
                    formErrorsMap['password'] = true;
                }

                if (
                    $registerStore[REGISTER_CONFIG.STEPS.CREDENTIALS.key].password.toLowerCase() !=
                    $registerStore[REGISTER_CONFIG.STEPS.CREDENTIALS.key].repeatPassword.toLowerCase()
                ) {
                    formErrors.push('Please make sure your passwords match.');
                    formErrorsMap['repeatPassword'] = true;
                }

                if ($registerStore[REGISTER_CONFIG.STEPS.CREDENTIALS.key].password.length <= 4) {
                    formErrors.push('Please make sure your password is longer than 4 characters.');
                    formErrorsMap['password'] = true;
                }
                break;
            default:
                break;
        }

        return formErrors.length == 0;
    }

    async function handleNext() {
        if (!validate(step)) {
            isFormValid = false;

            // Scroll to the top of the page
            setTimeout(() => {
                window.scrollTo(0, -100);
            }, 1000);
        } else {
            formErrors = [];
            formErrorsMap = {};
            isFormValid = true;

            dispatch('next', {
                currentStep: step,
                nextStep: getNextStep(),
            });
        }
    }

    function handlePrevious() {
        dispatch('previous', {
            currentStep: step,
            previousStep: getPreviousStep(),
        });
    }

    function handleJump(targetStep) {
        dispatch('jump', {
            currentStep: step,
            targetStep,
        });
    }

    function getNextStep() {
        const loanType = $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType;

        switch (step.key) {
            case REGISTER_CONFIG.STEPS.ESSENTIALS.key:
                return REGISTER_CONFIG.STEPS.LOAN;
            case REGISTER_CONFIG.STEPS.LOAN.key:
                if (
                    loanType == SHARED_CONFIG.LOAN_TYPES.PERSONAL &&
                    $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country?.value == 'TT'
                ) {
                    // return REGISTER_CONFIG.STEPS.INSURANCE;
                    return REGISTER_CONFIG.STEPS.DETAILS;
                } else {
                    return REGISTER_CONFIG.STEPS.DETAILS;
                }
            case REGISTER_CONFIG.STEPS.INSURANCE.key:
                return REGISTER_CONFIG.STEPS.DETAILS;
            case REGISTER_CONFIG.STEPS.DETAILS.key:
                return REGISTER_CONFIG.STEPS.FINANCIALS;
            case REGISTER_CONFIG.STEPS.FINANCIALS.key:
                if (loanType == SHARED_CONFIG.LOAN_TYPES.PERSONAL || loanType == SHARED_CONFIG.LOAN_TYPES.CAR) {
                    return REGISTER_CONFIG.STEPS.EMPLOYER;
                } else if (loanType == SHARED_CONFIG.LOAN_TYPES.BUSINESS) {
                    return REGISTER_CONFIG.STEPS.BUSINESS;
                }
            case REGISTER_CONFIG.STEPS.EMPLOYER.key:
                if (loanType == SHARED_CONFIG.LOAN_TYPES.CAR) {
                    return REGISTER_CONFIG.STEPS.CAR;
                } else {
                    return REGISTER_CONFIG.STEPS.CREDENTIALS;
                }
            case REGISTER_CONFIG.STEPS.BUSINESS.key:
                return REGISTER_CONFIG.STEPS.CREDENTIALS;
            case REGISTER_CONFIG.STEPS.CAR.key:
                return REGISTER_CONFIG.STEPS.CREDENTIALS;
            case REGISTER_CONFIG.STEPS.CREDENTIALS.key:
                return REGISTER_CONFIG.STEPS.REVIEW;
            default:
                return null;
        }
    }

    function getPreviousStep() {
        const loanType = $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType;

        switch (step.key) {
            case REGISTER_CONFIG.STEPS.LOAN.key:
                return REGISTER_CONFIG.STEPS.ESSENTIALS;
            case REGISTER_CONFIG.STEPS.INSURANCE.key:
                return REGISTER_CONFIG.STEPS.LOAN;
            case REGISTER_CONFIG.STEPS.DETAILS.key:
                if (
                    loanType == SHARED_CONFIG.LOAN_TYPES.PERSONAL &&
                    $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country?.value == 'TT'
                ) {
                    // return REGISTER_CONFIG.STEPS.INSURANCE;
                    return REGISTER_CONFIG.STEPS.LOAN;
                } else {
                    return REGISTER_CONFIG.STEPS.LOAN;
                }
            case REGISTER_CONFIG.STEPS.FINANCIALS.key:
                return REGISTER_CONFIG.STEPS.DETAILS;
            case REGISTER_CONFIG.STEPS.EMPLOYER.key:
                return REGISTER_CONFIG.STEPS.FINANCIALS;
            case REGISTER_CONFIG.STEPS.BUSINESS.key:
                return REGISTER_CONFIG.STEPS.FINANCIALS;
            case REGISTER_CONFIG.STEPS.CAR.key:
                return REGISTER_CONFIG.STEPS.EMPLOYER;
            case REGISTER_CONFIG.STEPS.CREDENTIALS.key:
                if (loanType == SHARED_CONFIG.LOAN_TYPES.PERSONAL) {
                    return REGISTER_CONFIG.STEPS.EMPLOYER;
                } else if (loanType == SHARED_CONFIG.LOAN_TYPES.BUSINESS) {
                    return REGISTER_CONFIG.STEPS.BUSINESS;
                } else if (loanType == SHARED_CONFIG.LOAN_TYPES.CAR) {
                    return REGISTER_CONFIG.STEPS.CAR;
                }
            case REGISTER_CONFIG.STEPS.REVIEW.key:
                return REGISTER_CONFIG.STEPS.CREDENTIALS;
            default:
                return null;
        }
    }

    // Todo: Remove this across all inputs but for now just return false
    function isReviewStep() {
        return false;
        // return step.key == REGISTER_CONFIG.STEPS.REVIEW.key;
    }
</script>

<div class="root">
    <form name="register-form" class="form">
        <div bind:this={formAnchor} style="display: none;" />

        {#if !isFormValid}
            <div class="form-errors animate__animated animate__fadeIn">
                <div class="form-errors__header">
                    We found some errors in the information you provided. Please fix the errors below before continuing.
                </div>
                {#each formErrors as error, i}
                    <div class="form-errors__error">{error}</div>
                {/each}
            </div>
        {/if}

        {#if step.key == REGISTER_CONFIG.STEPS.REVIEW.key}
            <div class="review-header">
                <div class="form__title form__title--big form__title--no-click">Review your application</div>
                <div class="review-header__desc">
                    Please take time to review your application. Click on each section to expand it and view your
                    details. Once you are satisfied click the Submit button to submit your application to Term Finance.
                </div>
            </div>
        {/if}

        {#if step.key == REGISTER_CONFIG.STEPS.ESSENTIALS.key}
            <div class="form__control">
                <label for="country" class:form__label--error={formErrorsMap['country']}>Select your Country</label>
                <div class="form__select">
                    <Select
                        items={countryOptions}
                        listPlacement="bottom"
                        bind:value={$registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country}
                        on:select={(event) => {
                            $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country = event.detail;
                            $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType = null;
                            $registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].address.country = event.detail;
                            $registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].address.country = event.detail;

                            // Add the area code to the user's phone number automatically
                            if (
                                $registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].phoneNumber.primary.full.length == 0
                            ) {
                                $registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].phoneNumber.primary.full =
                                    SHARED_CONFIG.COUNTRIES[event.detail.value].areaCode;
                            }
                        }}
                        placeholder="Country"
                        showChevron={true}
                        isDisabled={isReviewStep()}
                    />
                </div>
            </div>
            <div class="form__control">
                <label for="loanType" class:form__label--error={formErrorsMap['loanType']}>Choose a Loan Type</label>

                <!-- Personal Loan -->
                <div class="click-select">
                    <div
                        class="click-select-option"
                        class:click-select-option--disabled={step.key == REGISTER_CONFIG.STEPS.REVIEW.key ||
                            !$registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country}
                        class:click-select-option--selected={$registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key]
                            .loanType == SHARED_CONFIG.LOAN_TYPES.PERSONAL}
                        on:keydown
                        on:click={() => {
                            $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType =
                                SHARED_CONFIG.LOAN_TYPES.PERSONAL;
                        }}
                    >
                        <div class="click-select-option__header">
                            <div>
                                <div class="click-select-option__title">Personal Loan</div>
                                <div class="click-select-option__subtitle">For Employees</div>
                            </div>
                            <div class="click-select-option__icon">
                                {#if $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType == SHARED_CONFIG.LOAN_TYPES.PERSONAL}
                                    <Lottie jsonPath="lottie/register-checkmark.json" loop="false" />
                                {/if}
                            </div>
                        </div>
                        <div class="click-select-option__desc">
                            <p>
                                Responsible loans for employees of reputable companies. Choose your own repayment terms
                                and know exactly what you are repaying.
                            </p>
                        </div>
                    </div>

                    <!-- SME Loan -->
                    <div
                        class="click-select-option"
                        class:click-select-option--disabled={step.key == REGISTER_CONFIG.STEPS.REVIEW.key ||
                            !$registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country ||
                            ($registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country &&
                                !$registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country.isSME)}
                        class:click-select-option--selected={$registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key]
                            .loanType == SHARED_CONFIG.LOAN_TYPES.BUSINESS}
                        on:keydown
                        on:click={() => {
                            $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType =
                                SHARED_CONFIG.LOAN_TYPES.BUSINESS;
                        }}
                    >
                        <div class="click-select-option__header">
                            <div>
                                <div class="click-select-option__title">SME Loan</div>
                                <div class="click-select-option__subtitle">For Businesses</div>
                            </div>
                            <div class="click-select-option__icon">
                                {#if $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType == SHARED_CONFIG.LOAN_TYPES.BUSINESS}
                                    <Lottie jsonPath="lottie/register-checkmark.json" loop="false" />
                                {/if}
                            </div>
                        </div>
                        <div class="click-select-option__desc">
                            <p>
                                Efficient loans for small-medium size businesses. Choose your own repayment terms and
                                know exactly what your business is repaying.
                            </p>
                        </div>
                    </div>

                    <!-- Auto Loan -->
                    <div
                        class="click-select-option"
                        class:click-select-option--disabled={step.key == REGISTER_CONFIG.STEPS.REVIEW.key ||
                            !$registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country ||
                            ($registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country &&
                                !$registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country.isCar)}
                        class:click-select-option--selected={$registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key]
                            .loanType == SHARED_CONFIG.LOAN_TYPES.CAR}
                        on:keydown
                        on:click={() => {
                            $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType =
                                SHARED_CONFIG.LOAN_TYPES.CAR;
                        }}
                    >
                        <div class="click-select-option__header">
                            <div>
                                <div class="click-select-option__title">Car Loan</div>
                                <div class="click-select-option__subtitle">For Individuals & Businesses</div>
                            </div>
                            <div class="click-select-option__icon">
                                {#if $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType == SHARED_CONFIG.LOAN_TYPES.CAR}
                                    <Lottie jsonPath="lottie/register-checkmark.json" loop="false" />
                                {/if}
                            </div>
                        </div>
                        <div class="click-select-option__desc">
                            <p>
                                Convenient financing options for your next vehicle purchase or using your current
                                automobile to secure a loan. Choose your own repayment terms and know exactly what you
                                or your business is repaying.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- {/if} -->
        {/if}
        {#if step.key == REGISTER_CONFIG.STEPS.LOAN.key || step.key == REGISTER_CONFIG.STEPS.REVIEW.key}
            {#if step.key == REGISTER_CONFIG.STEPS.REVIEW.key}
                <div class="form__title" on:keydown on:click={() => (review.loan = !review.loan)}>
                    <div>{REGISTER_CONFIG.STEPS.LOAN.labels.title}</div>
                    <img
                        src={`/images/icon_caret_${review.loan ? 'up' : 'down'}.png`}
                        height="20"
                        alt="caret"
                        class="h-[20px]"
                    />
                </div>
            {/if}
            {#if step.key != REGISTER_CONFIG.STEPS.REVIEW.key || (step.key == REGISTER_CONFIG.STEPS.REVIEW.key && review.loan)}
                <LoanCalculator
                    disabled={isReviewStep()}
                    country={SHARED_CONFIG.COUNTRIES[
                        $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country.value
                    ]}
                    loanType={$registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType}
                    on:calculate={(event) => {
                        $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].amount = event.detail.borrowAmount;
                        $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].monthlyPaymentAmount =
                            event.detail.monthlyPaymentAmount;
                        $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].monthlyPaymentAmountBasicInsurance =
                            event.detail.monthlyPaymentAmountBasicInsurance;
                        $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].monthlyPaymentAmountProInsurance =
                            event.detail.monthlyPaymentAmountProInsurance;
                        $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].salary = event.detail.salaryAmount;
                        $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].numPayments = event.detail.numPayments;
                        $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].borrowAmountMultiplier =
                            event.detail.borrowAmountMultiplier;
                        $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].reason = event.detail.loanReason;
                        $registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].income.amount = event.detail.salaryAmount;
                        $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].car.loanType = event.detail.carLoanType;
                        $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].car.amount = event.detail.carAmount;
                        $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].car.downPaymentAmount =
                            event.detail.carDownPaymentAmount;
                    }}
                />
            {/if}
        {/if}

        {#if false}
            <!-- TODO: Enable Insurance -->
            {#if step.key == REGISTER_CONFIG.STEPS.INSURANCE.key || (step.key == REGISTER_CONFIG.STEPS.REVIEW.key && $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country?.value == 'TT' && $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType == SHARED_CONFIG.LOAN_TYPES.PERSONAL)}
                {#if step.key == REGISTER_CONFIG.STEPS.REVIEW.key}
                    <div class="form__title" on:keydown on:click={() => (review.insurance = !review.insurance)}>
                        <div>{REGISTER_CONFIG.STEPS.INSURANCE.labels.title}</div>
                        <img
                            src={`/images/icon_caret_${review.insurance ? 'up' : 'down'}.png`}
                            height="20"
                            alt="caret"
                            class="h-[20px]"
                        />
                    </div>
                {/if}
                {#if step.key != REGISTER_CONFIG.STEPS.REVIEW.key || (step.key == REGISTER_CONFIG.STEPS.REVIEW.key && review.insurance && $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country?.value == 'TT' && $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType == SHARED_CONFIG.LOAN_TYPES.PERSONAL)}
                    <div class="mb-8 flex w-full flex-col gap-y-4">
                        <div class="text-xl font-medium text-primary">Select your payment protection plan.</div>
                    </div>

                    {#if $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country}
                        <div
                            class="mb-4 flex w-full flex-col items-center justify-center gap-x-2 gap-y-2 lg:flex-row lg:gap-y-0"
                        >
                            <!-- Pro -->
                            <div
                                on:keydown
                                on:click={() => {
                                    $registerStore[REGISTER_CONFIG.STEPS.INSURANCE.key].insurance =
                                        SHARED_CONFIG.PERSONAL_INSURANCE_TYPES.PRO.id;
                                }}
                                class:!opacity-100={$registerStore[REGISTER_CONFIG.STEPS.INSURANCE.key].insurance ==
                                    SHARED_CONFIG.PERSONAL_INSURANCE_TYPES.PRO.id}
                                class:border-primary={$registerStore[REGISTER_CONFIG.STEPS.INSURANCE.key].insurance ==
                                    SHARED_CONFIG.PERSONAL_INSURANCE_TYPES.PRO.id}
                                class:!shadow-lg={$registerStore[REGISTER_CONFIG.STEPS.INSURANCE.key].insurance ==
                                    SHARED_CONFIG.PERSONAL_INSURANCE_TYPES.PRO.id}
                                class="group w-full flex-1 cursor-pointer self-stretch rounded-md border border-gray-300 bg-white opacity-60 shadow-sm transition-all duration-75 hover:border-primary hover:bg-secondary hover:shadow-lg"
                            >
                                <div
                                    class="flex h-[150px] w-full items-center justify-center rounded-md bg-gradient-to-r from-violet-600 to-indigo-600"
                                >
                                    <div class="w-[150px]">
                                        <Lottie jsonPath="lottie/rocket.json" />
                                    </div>
                                </div>
                                <div
                                    class="rounded-md transition-all duration-75 group-hover:bg-secondary"
                                    class:bg-secondary={$registerStore[REGISTER_CONFIG.STEPS.INSURANCE.key].insurance ==
                                        SHARED_CONFIG.PERSONAL_INSURANCE_TYPES.PRO.id}
                                >
                                    <div class="px-2 py-4">
                                        <div class="mb-2 flex w-full items-center justify-between">
                                            <div class="flex items-center justify-start gap-x-2 text-3xl font-bold">
                                                <img src="/images/nav_logo@2x.png" class="h-[45px]" alt="tf" />
                                                <span>Pro</span>
                                            </div>
                                            <div class="badge border border-primary text-xs text-primary">Popular</div>
                                        </div>
                                        <div class="text-sm text-gray-400">
                                            Our most popular plan with complete coverage.
                                        </div>
                                    </div>
                                    <div class="flex w-full flex-col items-center gap-y-2">
                                        <div
                                            class="grid w-full grid-cols-3 items-center justify-center gap-x-3 border-b border-gray-300 p-2 text-sm text-gray-400"
                                        >
                                            <div class="w-full font-medium text-black">Death</div>
                                            <div class="flex items-center justify-center">
                                                <i class="fa fa-circle-check fa-lg text-success"></i>
                                            </div>
                                            <div class="text-primary">Full paid loan + TTD 10,000</div>
                                        </div>
                                        <div
                                            class="grid w-full flex-1 grid-cols-3 items-stretch gap-x-3 border-b border-gray-300 p-2 text-sm text-gray-400"
                                        >
                                            <div class="w-full font-medium text-black">Critical Illness</div>
                                            <div class="flex items-center justify-center">
                                                <i class="fa fa-circle-check fa-lg text-success"></i>
                                            </div>
                                            <div class="text-primary">Full paid loan</div>
                                        </div>
                                        <div
                                            class="grid w-full flex-1 grid-cols-3 items-stretch gap-x-3 border-b border-gray-300 p-2 text-sm text-gray-400"
                                        >
                                            <div class="w-full font-medium text-black">Temporary Disability</div>
                                            <div class="flex items-center justify-center">
                                                <i class="fa fa-circle-check fa-lg text-success"></i>
                                            </div>
                                            <div class="text-primary">Up to 6 monthly payments</div>
                                        </div>
                                        <div
                                            class="grid w-full flex-1 grid-cols-3 items-stretch gap-x-3 border-b border-gray-300 p-2 text-sm text-gray-400"
                                        >
                                            <div class="w-full font-medium text-black">
                                                Involuntary Loss of Employment
                                            </div>
                                            <div class="flex items-center justify-center">
                                                <i class="fa fa-circle-check fa-lg text-success"></i>
                                            </div>
                                            <div class="text-primary">Up to 6 monthly payments</div>
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-center gap-y-2 px-2 py-4">
                                        <div class="flex flex-row items-center justify-between">
                                            <div class="font-base text-sm text-gray-400">Current Monthly Payment</div>
                                            <div class="font-base text-sm font-medium text-primary">
                                                {formatCurrency(
                                                    $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].monthlyPaymentAmount,
                                                    $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country
                                                        ? SHARED_CONFIG.COUNTRIES[
                                                              $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key]
                                                                  .country?.value
                                                          ]?.locale
                                                        : 'en-TT',
                                                    $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country
                                                        ? SHARED_CONFIG.COUNTRIES[
                                                              $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key]
                                                                  .country?.value
                                                          ]?.currency
                                                        : 'TTD',
                                                )}
                                            </div>
                                        </div>
                                        <div class="mt-2 flex flex-row items-center justify-between">
                                            <div class="font-base text-base text-black">New Monthly Payment</div>
                                            <div class="font-base text-lg font-bold text-primary">
                                                {formatCurrency(
                                                    $registerStore[REGISTER_CONFIG.STEPS.LOAN.key]
                                                        .monthlyPaymentAmountProInsurance,
                                                    $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country
                                                        ? SHARED_CONFIG.COUNTRIES[
                                                              $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key]
                                                                  .country?.value
                                                          ]?.locale
                                                        : 'en-TT',
                                                    $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country
                                                        ? SHARED_CONFIG.COUNTRIES[
                                                              $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key]
                                                                  .country?.value
                                                          ]?.currency
                                                        : 'TTD',
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Basic -->
                            <div
                                on:keydown
                                on:click={() => {
                                    $registerStore[REGISTER_CONFIG.STEPS.INSURANCE.key].insurance =
                                        SHARED_CONFIG.PERSONAL_INSURANCE_TYPES.BASIC.id;
                                }}
                                class:!opacity-100={$registerStore[REGISTER_CONFIG.STEPS.INSURANCE.key].insurance ==
                                    SHARED_CONFIG.PERSONAL_INSURANCE_TYPES.BASIC.id}
                                class:border-primary={$registerStore[REGISTER_CONFIG.STEPS.INSURANCE.key].insurance ==
                                    SHARED_CONFIG.PERSONAL_INSURANCE_TYPES.BASIC.id}
                                class:!shadow-lg={$registerStore[REGISTER_CONFIG.STEPS.INSURANCE.key].insurance ==
                                    SHARED_CONFIG.PERSONAL_INSURANCE_TYPES.BASIC.id}
                                class="group w-full flex-1 cursor-pointer self-stretch rounded-md border border-gray-300 bg-white opacity-60 shadow-sm transition-all duration-75 hover:border-primary hover:bg-secondary hover:shadow-lg"
                            >
                                <div
                                    class="flex h-[150px] w-full items-center justify-center rounded-md bg-gradient-to-r from-red-200 to-orange-100"
                                >
                                    <div class="w-[100px]">
                                        <Lottie jsonPath="lottie/umbrella.json" />
                                    </div>
                                </div>
                                <div
                                    class="rounded-md transition-all duration-75 group-hover:bg-secondary"
                                    class:bg-secondary={$registerStore[REGISTER_CONFIG.STEPS.INSURANCE.key].insurance ==
                                        SHARED_CONFIG.PERSONAL_INSURANCE_TYPES.BASIC.id}
                                >
                                    <div class="px-2 py-4">
                                        <div class="mb-2 flex w-full items-center justify-between">
                                            <div class="flex items-center justify-start gap-x-2 text-3xl font-bold">
                                                <img src="/images/nav_logo@2x.png" class="h-[45px]" alt="tf" />
                                                <span>Basic</span>
                                            </div>
                                        </div>
                                        <div class="text-sm text-gray-400">Our basic plan with great coverage.</div>
                                    </div>
                                    <div class="flex w-full flex-col items-center gap-y-2">
                                        <div
                                            class="grid w-full grid-cols-3 items-center justify-center gap-x-3 border-b border-gray-300 p-2 text-sm text-gray-400"
                                        >
                                            <div class="w-full font-medium text-black">Death</div>
                                            <div class="flex items-center justify-center">
                                                <i class="fa fa-circle-x fa-lg text-error"></i>
                                            </div>
                                            <div class="text-gray-400">Not covered</div>
                                        </div>
                                        <div
                                            class="grid w-full flex-1 grid-cols-3 items-stretch gap-x-3 border-b border-gray-300 p-2 text-sm text-gray-400"
                                        >
                                            <div class="w-full font-medium text-black">Critical Illness</div>
                                            <div class="flex items-center justify-center">
                                                <i class="fa fa-circle-check fa-lg text-success"></i>
                                            </div>
                                            <div class="text-primary">Full paid loan</div>
                                        </div>
                                        <div
                                            class="grid w-full flex-1 grid-cols-3 items-stretch gap-x-3 border-b border-gray-300 p-2 text-sm text-gray-400"
                                        >
                                            <div class="w-full font-medium text-black">Temporary Disability</div>
                                            <div class="flex items-center justify-center">
                                                <i class="fa fa-circle-check fa-lg text-warning"></i>
                                            </div>
                                            <div class="text-primary">Up to 3 monthly payments</div>
                                        </div>
                                        <div
                                            class="grid w-full flex-1 grid-cols-3 items-stretch gap-x-3 border-b border-gray-300 p-2 text-sm text-gray-400"
                                        >
                                            <div class="w-full font-medium text-black">
                                                Involuntary Loss of Employment
                                            </div>
                                            <div class="flex items-center justify-center">
                                                <i class="fa fa-circle-check fa-lg text-warning"></i>
                                            </div>
                                            <div class="text-primary">Up to 3 monthly payments</div>
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-center gap-y-2 px-2 py-4">
                                        <div class="flex flex-row items-center justify-between">
                                            <div class="font-base text-sm text-gray-400">Current Monthly Payment</div>
                                            <div class="font-base text-sm font-medium text-primary">
                                                {formatCurrency(
                                                    $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].monthlyPaymentAmount,
                                                    $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country
                                                        ? SHARED_CONFIG.COUNTRIES[
                                                              $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key]
                                                                  .country?.value
                                                          ]?.locale
                                                        : 'en-TT',
                                                    $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country
                                                        ? SHARED_CONFIG.COUNTRIES[
                                                              $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key]
                                                                  .country?.value
                                                          ]?.currency
                                                        : 'TTD',
                                                )}
                                            </div>
                                        </div>
                                        <div class="mt-2 flex flex-row items-center justify-between">
                                            <div class="font-base text-base text-black">New Monthly Payment</div>
                                            <div class="font-base text-lg font-bold text-primary">
                                                {formatCurrency(
                                                    $registerStore[REGISTER_CONFIG.STEPS.LOAN.key]
                                                        .monthlyPaymentAmountBasicInsurance,
                                                    $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country
                                                        ? SHARED_CONFIG.COUNTRIES[
                                                              $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key]
                                                                  .country?.value
                                                          ]?.locale
                                                        : 'en-TT',
                                                    $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country
                                                        ? SHARED_CONFIG.COUNTRIES[
                                                              $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key]
                                                                  .country?.value
                                                          ]?.currency
                                                        : 'TTD',
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/if}

                    <div
                        on:keydown
                        on:click={() => {
                            $registerStore[REGISTER_CONFIG.STEPS.INSURANCE.key].insurance = null;
                        }}
                        class:!opacity-100={!$registerStore[REGISTER_CONFIG.STEPS.INSURANCE.key].insurance}
                        class:!bg-secondary={!$registerStore[REGISTER_CONFIG.STEPS.INSURANCE.key].insurance}
                        class:border-primary={!$registerStore[REGISTER_CONFIG.STEPS.INSURANCE.key].insurance}
                        class:!shadow-lg={!$registerStore[REGISTER_CONFIG.STEPS.INSURANCE.key].insurance}
                        class="w-full cursor-pointer rounded-md border border-gray-300 bg-white p-4 text-lg font-normal text-black opacity-60 shadow-sm transition-all hover:border-primary hover:bg-secondary hover:shadow-md"
                    >
                        No thanks, I don't need coverage.
                    </div>
                {/if}
            {/if}
        {/if}
        {#if step.key == REGISTER_CONFIG.STEPS.DETAILS.key || step.key == REGISTER_CONFIG.STEPS.REVIEW.key}
            {#if step.key == REGISTER_CONFIG.STEPS.REVIEW.key}
                <div class="form__title" on:keydown on:click={() => (review.details = !review.details)}>
                    <div>{REGISTER_CONFIG.STEPS.DETAILS.labels.title}</div>
                    <img
                        src={`/images/icon_caret_${review.details ? 'up' : 'down'}.png`}
                        height="20"
                        alt="caret"
                        class="h-[20px]"
                    />
                </div>
            {/if}
            {#if step.key != REGISTER_CONFIG.STEPS.REVIEW.key || (step.key == REGISTER_CONFIG.STEPS.REVIEW.key && review.details)}
                <div class="form__control">
                    <label for="firstName" class:form__label--error={formErrorsMap['firstName']}>First Name</label>
                    <input
                        name="firstName"
                        bind:value={$registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].firstName}
                        disabled={isReviewStep()}
                    />
                </div>
                <div class="form__control">
                    <label for="lastName" class:form__label--error={formErrorsMap['lastName']}>Last Name</label>
                    <input
                        name="lastName"
                        bind:value={$registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].lastName}
                        disabled={isReviewStep()}
                    />
                </div>
                <div class="form__control">
                    <label for="emailAddress" class:form__label--error={formErrorsMap['emailAddress']}
                        >Email Address</label
                    >
                    <input
                        type="email"
                        name="emailAddress"
                        bind:value={$registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].emailAddress}
                        disabled={isReviewStep()}
                    />
                </div>
                <div class="form__control">
                    <label for="confirmEmailAddress" class:form__label--error={formErrorsMap['confirmEmailAddress']}
                        >Confirm Email Address</label
                    >
                    <div class="form__desc">Please retype your email address to confirm it was entered correctly.</div>
                    <input
                        name="confirmEmailAddress"
                        bind:value={$registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].confirmEmailAddress}
                        disabled={isReviewStep()}
                    />
                </div>
                <div class="form__control">
                    <label for="dobDate" class:form__label--error={formErrorsMap['dob']}>Date of Birth</label>
                    <DateSelector
                        date={$registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].dob.date}
                        disabled={isReviewStep()}
                        on:change={({ detail }) => {
                            $registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].dob.date = detail.date;
                        }}
                    />
                </div>
                <div class="form__control">
                    <label for="gender" class:form__label--error={formErrorsMap['gender']}>Gender</label>
                    <div class="form__select">
                        <Select
                            items={REGISTER_CONFIG.OPTIONS.GENDER}
                            listPlacement="bottom"
                            bind:value={$registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].gender}
                            on:select={(event) => {
                                $registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].gender = event.detail;
                            }}
                            placeholder="Select Gender"
                            showChevron={true}
                            isDisabled={isReviewStep()}
                        />
                    </div>
                </div>
                <div class="form__control">
                    <label for="outstandingLoans">Are you classified as a PEP (Polticially Exposed Person)?</label>
                    <div class="form__select">
                        <Select
                            items={REGISTER_CONFIG.OPTIONS.YES_NO}
                            listPlacement="bottom"
                            bind:value={$registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].pep}
                            on:select={(event) => {
                                $registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].pep = event.detail;
                            }}
                            placeholder="Select Yes/No"
                            showChevron={true}
                            showIndicator={true}
                            isClearable={false}
                            isDisabled={isReviewStep()}
                        />
                    </div>
                </div>
                <div class="form__control">
                    <label for="primaryPhone" class:form__label--error={formErrorsMap['phoneNumberPrimary']}
                        >Primary Phone Number</label
                    >
                    <MaskInput
                        type="tel"
                        mask={phoneMask}
                        showMask
                        value={$registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].phoneNumber.primary.full}
                        maskChar="X"
                        on:change={({ detail }) => {
                            $registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].phoneNumber.primary.full =
                                detail.inputState.maskedValue;
                        }}
                    />
                </div>
                <div class="form__control">
                    <label
                        for="address"
                        class:form__label--error={formErrorsMap['address.city'] || formErrorsMap['address.street']}
                        >Address</label
                    >
                    <div class="stack-input">
                        <div class="stack-input__input">
                            <input
                                name="address.street"
                                placeholder="Street"
                                bind:value={$registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].address.street}
                                disabled={isReviewStep()}
                            />
                        </div>
                        <div class="stack-input__input">
                            <input
                                name="address.city"
                                placeholder="City"
                                bind:value={$registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].address.city}
                                disabled={isReviewStep()}
                            />
                        </div>
                        <div class="stack-input__input">
                            <div class="form__select">
                                <Select
                                    items={countryOptions}
                                    listPlacement="bottom"
                                    bind:value={$registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country}
                                    on:select={(event) => {
                                        $registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].address.country =
                                            event.detail;
                                    }}
                                    placeholder="Country"
                                    showChevron={true}
                                    isDisabled={$registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country != null}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            {/if}
        {/if}
        {#if step.key == REGISTER_CONFIG.STEPS.FINANCIALS.key || step.key == REGISTER_CONFIG.STEPS.REVIEW.key}
            {#if step.key == REGISTER_CONFIG.STEPS.REVIEW.key}
                <div class="form__title" on:keydown on:click={() => (review.financials = !review.financials)}>
                    <div>{REGISTER_CONFIG.STEPS.FINANCIALS.labels.title}</div>
                    <img
                        src={`/images/icon_caret_${review.financials ? 'up' : 'down'}.png`}
                        height="20"
                        alt="caret"
                        class="h-[20px]"
                    />
                </div>
            {/if}
            {#if step.key != REGISTER_CONFIG.STEPS.REVIEW.key || (step.key == REGISTER_CONFIG.STEPS.REVIEW.key && review.financials)}
                <div class="form__control">
                    <label for="bankName" class:form__label--error={formErrorsMap['bankName']}>Bank Name</label>
                    <input
                        name="bankName"
                        bind:value={$registerStore[REGISTER_CONFIG.STEPS.FINANCIALS.key].bankName}
                        disabled={isReviewStep()}
                    />
                </div>
                <div class="form__control">
                    <label for="bankAccount.number" class:form__label--error={formErrorsMap['bankAccount.number']}
                        >Account Number</label
                    >
                    <input
                        name="bankAccount.number"
                        bind:value={$registerStore[REGISTER_CONFIG.STEPS.FINANCIALS.key].bankAccount.number}
                        disabled={isReviewStep()}
                    />
                </div>
                <div class="form__control">
                    <label for="bankAccount.type" class:form__label--error={formErrorsMap['bankAccount.type']}
                        >Account Type</label
                    >
                    <div class="form__select">
                        <Select
                            items={REGISTER_CONFIG.OPTIONS.ACCOUNT_TYPE}
                            listPlacement="bottom"
                            bind:value={$registerStore[REGISTER_CONFIG.STEPS.FINANCIALS.key].bankAccount.type}
                            on:select={(event) => {
                                $registerStore[REGISTER_CONFIG.STEPS.FINANCIALS.key].bankAccount.type = event.detail;
                            }}
                            placeholder="Select Account Type"
                            showChevron={true}
                            isDisabled={isReviewStep()}
                        />
                    </div>
                </div>
                <div class="form__disclaimer">
                    Please note that the information requested is confidential and only enables Term Finance to send
                    funds to your account.
                </div>
            {/if}
        {/if}
        {#if step.key == REGISTER_CONFIG.STEPS.BUSINESS.key || (step.key == REGISTER_CONFIG.STEPS.REVIEW.key && $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType === SHARED_CONFIG.LOAN_TYPES.BUSINESS)}
            {#if step.key == REGISTER_CONFIG.STEPS.REVIEW.key}
                <div class="form__title" on:keydown on:click={() => (review.business = !review.business)}>
                    <div>{REGISTER_CONFIG.STEPS.BUSINESS.labels.title}</div>
                    <img
                        src={`/images/icon_caret_${review.business ? 'up' : 'down'}.png`}
                        height="20"
                        alt="caret"
                        class="h-[20px]"
                    />
                </div>
            {/if}
            {#if step.key != REGISTER_CONFIG.STEPS.REVIEW.key || (step.key == REGISTER_CONFIG.STEPS.REVIEW.key && review.business)}
                <div class="form__control">
                    <label for="name" class:form__label--error={formErrorsMap['name']}>Business Name</label>
                    <input
                        name="businessName"
                        bind:value={$registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].name}
                        disabled={isReviewStep()}
                    />
                </div>
                <div class="form__control">
                    <label
                        for="address"
                        class:form__label--error={formErrorsMap['address.street'] || formErrorsMap['address.city']}
                        >Business Address</label
                    >
                    <div class="stack-input">
                        <div class="stack-input__input">
                            <input
                                name="businessAddress.street"
                                placeholder="Street"
                                bind:value={$registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].address.street}
                                disabled={isReviewStep()}
                            />
                        </div>
                        <div class="stack-input__input">
                            <input
                                name="businessAddress.city"
                                placeholder="City"
                                bind:value={$registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].address.city}
                                disabled={isReviewStep()}
                            />
                        </div>
                        <div class="stack-input__input">
                            <div class="form__select">
                                <Select
                                    items={countryOptions}
                                    listPlacement="bottom"
                                    bind:value={$registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country}
                                    on:select={(event) => {
                                        $registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].address.country =
                                            event.detail;
                                    }}
                                    placeholder="Country"
                                    showChevron={true}
                                    isDisabled={$registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country != null}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form__control">
                    <label for="businessPhoneNumber" class:form__label--error={formErrorsMap['phoneNumber']}
                        >Business Phone Number</label
                    >
                    <MaskInput
                        mask="000-000-0000"
                        showMask
                        maskChar="0"
                        value={$registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].phoneNumber.full}
                        on:change={({ detail }) => {
                            $registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].phoneNumber.full =
                                detail.inputState.maskedValue;
                        }}
                    />
                </div>
                <div class="form__control">
                    <label for="businessStartDate" class:form__label--error={formErrorsMap['startDate']}
                        >When did your business start operations?</label
                    >
                    <DateSelector
                        date={$registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].startDate.date}
                        disabled={isReviewStep()}
                        on:change={({ detail }) => {
                            $registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].startDate.date = detail.date;
                        }}
                    />
                </div>
                <div class="form__control">
                    <label for="numEmployees.fullTime" class:form__label--error={formErrorsMap['numEmployees.fullTime']}
                        >Number of Full-time Employees</label
                    >
                    <input
                        name="numFullTimeEmployees"
                        type="number"
                        min="0"
                        bind:value={$registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].numEmployees.fullTime}
                        disabled={step.key == REGISTER_CONFIG.STEPS.REVIEW.key}
                    />
                </div>
                <div class="form__control">
                    <label for="numEmployees.partTime" class:form__label--error={formErrorsMap['numEmployees.partTime']}
                        >Number of Part-time Employees</label
                    >
                    <input
                        name="numPartTimeEmployees"
                        type="number"
                        min="0"
                        bind:value={$registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].numEmployees.partTime}
                        disabled={step.key == REGISTER_CONFIG.STEPS.REVIEW.key}
                    />
                </div>
                <div class="form__control">
                    <label
                        for="averageMonthlySalesRevenue"
                        class:form__label--error={formErrorsMap['averageMonthlySalesRevenue']}
                        >Average Monthly Sales Revenue</label
                    >
                    <div class="slider">
                        <RangeSlider
                            range="min"
                            min={0}
                            max={100000}
                            step={1000}
                            values={[$registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].averageMonthlySalesRevenue]}
                            first="label"
                            last="label"
                            float
                            formatter={(v) =>
                                formatCurrency(
                                    v,
                                    SHARED_CONFIG.COUNTRIES[
                                        $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country.value
                                    ].locale,
                                    SHARED_CONFIG.COUNTRIES[
                                        $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country.value
                                    ].currency,
                                )}
                            on:change={(event) => {
                                $registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].averageMonthlySalesRevenue =
                                    event.detail.value;
                            }}
                            disabled={isReviewStep()}
                        />
                    </div>
                </div>
                <div class="form__control">
                    <label for="outstandingLoans">Do you have any outstanding loans?</label>
                    <div class="form__select">
                        <Select
                            items={REGISTER_CONFIG.OPTIONS.YES_NO}
                            listPlacement="bottom"
                            bind:value={$registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].outstandingLoans}
                            on:select={(event) => {
                                $registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].outstandingLoans = event.detail;
                            }}
                            placeholder="Select Yes/No"
                            showChevron={true}
                            isDisabled={isReviewStep()}
                        />
                    </div>
                </div>
                {#if $registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].outstandingLoans && $registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].outstandingLoans.value}
                    <div class="form__control">
                        <label for="outstandingLoansDesc">Please list all of your oustanding loans.</label>
                        <textarea
                            class="rounded-md border border-gray-300"
                            bind:value={$registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].outstandingLoansDescription}
                        />
                    </div>
                {/if}
                <div class="form__control">
                    <label for="reasonForBorrowing" class:form__label--error={formErrorsMap['reasonForBorrowing']}
                        >What is the intended use of the funds your business wishes to borrow?</label
                    >
                    <textarea
                        bind:value={$registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].reasonForBorrowing}
                        class="rounded-md border border-gray-300"
                    />
                </div>
                <div class="form__control">
                    <label
                        class="form__disclosure"
                        for="transUnionDisclosure"
                        class:form__label--error={formErrorsMap['disclosures.transunion']}>Terms and Conditions</label
                    >
                    <br />
                    <div class="form__desc">
                        <p>
                            I/We certify that the information provided in this application is true and correct as of the
                            date of this application and acknowledge my/our understanding that any intentional or
                            negligent misrepresentation(s) of the information contained this application may result in
                            civil liability for monetary damages to Term Finance (SME TT) Limited, its agents,
                            successors and assigns, insurers and any other person who may suffer any loss due to
                            reliance upon any misrepresentation which I/we have made on this application.
                        </p>
                        <p>
                            As part of the loan application process, Term Finance (SME TT) is required to perform due
                            diligence on the business and owner(s)/directors. By checking this box, I/we consent and
                            authorise Term Finance (SME TT) Limited to obtain additional information on my/our credit
                            history, from any credit bureau, lenders or grantors of credit. Further, by checking this
                            box I understand that Term Finance may contact any stakeholder of the business and/or
                            owner(s)/directors including but not limited to suppliers, customers and employees in the
                            process of evaluating the credit worthiness of the business and/or owner(s)/directors.
                        </p>
                        <p>
                            I/We jointly and severally agree to indemnify Term Finance (SME TT) against any loss,
                            claims, damages, liabilities, actions and proceedings, legal and or other expense which may
                            be directly or reasonably incurred as a consequence of such disclosure on your part and of
                            any intentional or negligent misrepresentation made by me/us on this application.
                        </p>
                    </div>
                    <br />
                    <div class="form__select">
                        <Select
                            items={REGISTER_CONFIG.OPTIONS.YES_NO}
                            bind:value={$registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].disclosures.transunion}
                            on:select={(event) => {
                                $registerStore[REGISTER_CONFIG.STEPS.BUSINESS.key].disclosures.transunion =
                                    event.detail;
                            }}
                            placeholder="Select Yes/No"
                            showChevron={true}
                            isDisabled={isReviewStep()}
                        />
                    </div>
                </div>
            {/if}
        {/if}

        <!-- Car -->
        {#if step.key == REGISTER_CONFIG.STEPS.CAR.key || (step.key == REGISTER_CONFIG.STEPS.REVIEW.key && $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType === SHARED_CONFIG.LOAN_TYPES.CAR)}
            {#if step.key == REGISTER_CONFIG.STEPS.REVIEW.key}
                <div class="form__title" on:keydown on:click={() => (review.car = !review.car)}>
                    <div>{REGISTER_CONFIG.STEPS.CAR.labels.title}</div>
                    <img
                        src={`/images/icon_caret_${review.car ? 'up' : 'down'}.png`}
                        height="20"
                        alt="caret"
                        class="h-[20px]"
                    />
                </div>
            {/if}
            {#if step.key != REGISTER_CONFIG.STEPS.REVIEW.key || (step.key == REGISTER_CONFIG.STEPS.REVIEW.key && review.car)}
                <div class="form__control">
                    <label for="driversLicense" class:form__label--error={formErrorsMap['driversLicense']}
                        >Do you have a valid drivers license?</label
                    >
                    <div class="form__select">
                        <Select
                            items={REGISTER_CONFIG.OPTIONS.YES_NO}
                            listPlacement="bottom"
                            bind:value={$registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].driversLicense}
                            on:select={(event) => {
                                $registerStore[REGISTER_CONFIG.STEPS.CAR.key].driversLicense = event.detail;
                            }}
                            placeholder="Select Yes/No"
                            showChevron={true}
                            showIndicator={true}
                            isClearable={false}
                            isDisabled={isReviewStep()}
                        />
                    </div>
                </div>

                {#if $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].car.loanType && $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].car.loanType.value != SHARED_CONFIG.CAR_LOAN_TYPES.PURCHASE}
                    <div class="form__control">
                        <label for="vehicleDebt.hasDebt" class:form__label--error={formErrorsMap['vehicleDebt.hasDebt']}
                            >Do you owe any money on your current vehicle?</label
                        >
                        <div class="form__select">
                            <Select
                                items={REGISTER_CONFIG.OPTIONS.YES_NO}
                                listPlacement="bottom"
                                bind:value={$registerStore[REGISTER_CONFIG.STEPS.CAR.key].vehicleDebt.hasDebt}
                                on:select={(event) => {
                                    $registerStore[REGISTER_CONFIG.STEPS.CAR.key].vehicleDebt.hasDebt = event.detail;
                                }}
                                placeholder="Select Yes/No"
                                showChevron={true}
                                showIndicator={true}
                                isClearable={false}
                                isDisabled={isReviewStep()}
                            />
                        </div>
                    </div>
                {/if}

                {#if $registerStore[REGISTER_CONFIG.STEPS.CAR.key].vehicleDebt.hasDebt && $registerStore[REGISTER_CONFIG.STEPS.CAR.key].vehicleDebt.hasDebt.value}
                    <div class="form__control">
                        <label for="vehicleDebt.balance" class:form__label--error={formErrorsMap['vehicleDebt.balance']}
                            >What is the outstanding balance on your current vehicle?</label
                        >
                        <div class="slider">
                            <RangeSlider
                                range="min"
                                min={0}
                                max={80000}
                                step={500}
                                values={[$registerStore[REGISTER_CONFIG.STEPS.CAR.key].vehicleDebt.balance]}
                                first="label"
                                last="label"
                                float
                                formatter={(v) => formatCurrency(v, 'tt', 'TTD')}
                                on:change={(event) => {
                                    $registerStore[REGISTER_CONFIG.STEPS.CAR.key].vehicleDebt.balance =
                                        event.detail.value;
                                }}
                                disabled={isReviewStep()}
                            />
                        </div>
                    </div>

                    <div class="form__control">
                        <label
                            for="vehicleDebt.monthlyPayment"
                            class:form__label--error={formErrorsMap['vehicleDebt.monthlyPayment']}
                            >What is the monthly payment on your current vehicle?</label
                        >
                        <div class="slider">
                            <RangeSlider
                                range="min"
                                min={0}
                                max={10000}
                                step={500}
                                values={[$registerStore[REGISTER_CONFIG.STEPS.CAR.key].vehicleDebt.monthlyPayment]}
                                first="label"
                                last="label"
                                float
                                formatter={(v) => formatCurrency(v, 'tt', 'TTD')}
                                on:change={(event) => {
                                    $registerStore[REGISTER_CONFIG.STEPS.CAR.key].vehicleDebt.monthlyPayment =
                                        event.detail.value;
                                }}
                                disabled={isReviewStep()}
                            />
                        </div>
                    </div>
                {/if}

                <div class="form__control">
                    <label
                        for="vehicleDebt.hasDebt"
                        class:form__label--error={formErrorsMap['vehicleDebt.insurance.isValid']}
                        >Does your vehicle have valid insurance?</label
                    >
                    <div class="form__select">
                        <Select
                            items={REGISTER_CONFIG.OPTIONS.YES_NO}
                            listPlacement="bottom"
                            bind:value={$registerStore[REGISTER_CONFIG.STEPS.CAR.key].insurance.isValid}
                            on:select={(event) => {
                                $registerStore[REGISTER_CONFIG.STEPS.CAR.key].insurance.isValid = event.detail;
                            }}
                            placeholder="Select Yes/No"
                            showChevron={true}
                            showIndicator={true}
                            isClearable={false}
                            isDisabled={isReviewStep()}
                        />
                    </div>
                </div>

                {#if $registerStore[REGISTER_CONFIG.STEPS.CAR.key].insurance.isValid && $registerStore[REGISTER_CONFIG.STEPS.CAR.key].insurance.isValid.value}
                    <div class="form__control">
                        <label
                            for="vehicleDebt.hasDebt"
                            class:form__label--error={formErrorsMap['vehicleDebt.insurance.isValid']}
                            >What type of insurance does your vehicle have?</label
                        >
                        <div class="form__select">
                            <Select
                                items={REGISTER_CONFIG.OPTIONS.INSURANCE_TYPES}
                                listPlacement="bottom"
                                bind:value={$registerStore[REGISTER_CONFIG.STEPS.CAR.key].insurance.type}
                                on:select={(event) => {
                                    $registerStore[REGISTER_CONFIG.STEPS.CAR.key].insurance.type = event.detail;
                                }}
                                placeholder="Select Insurance Type"
                                showChevron={true}
                                showIndicator={true}
                                isClearable={false}
                                isDisabled={isReviewStep()}
                            />
                        </div>
                    </div>

                    <div class="form__control">
                        <label for="insurance.company" class:form__label--error={formErrorsMap['insurance.company']}
                            >Which agency is your vehicle insured with?</label
                        >
                        <input
                            name="insurance.company"
                            bind:value={$registerStore[REGISTER_CONFIG.STEPS.CAR.key].insurance.company}
                            disabled={isReviewStep()}
                        />
                    </div>
                {/if}

                <div class="form__control">
                    <label for="licensePlateNumber" class:form__label--error={formErrorsMap['licensePlateNumber']}
                        >What is your license plate number?</label
                    >
                    <input
                        name="licensePlateNumber"
                        bind:value={$registerStore[REGISTER_CONFIG.STEPS.CAR.key].licensePlateNumber}
                        disabled={isReviewStep()}
                    />
                </div>

                <div class="form__control">
                    <label for="vehicle.make" class:form__label--error={formErrorsMap['vehicle.make']}
                        >What is the make of your vehicle?</label
                    >
                    <input
                        name="vehicle.makle"
                        bind:value={$registerStore[REGISTER_CONFIG.STEPS.CAR.key].vehicle.make}
                        disabled={isReviewStep()}
                    />
                </div>

                <div class="form__control">
                    <label for="vehicle.model" class:form__label--error={formErrorsMap['vehicle.model']}
                        >What is the model of your vehicle?</label
                    >
                    <input
                        name="vehicle.model"
                        bind:value={$registerStore[REGISTER_CONFIG.STEPS.CAR.key].vehicle.model}
                        disabled={isReviewStep()}
                    />
                </div>

                <div class="form__control">
                    <label for="vehicle.year" class:form__label--error={formErrorsMap['vehicle.year']}
                        >What is the year of your vehicle?</label
                    >
                    <div class="form__select">
                        <Select
                            items={yearOptions}
                            listPlacement="bottom"
                            bind:value={$registerStore[REGISTER_CONFIG.STEPS.CAR.key].vehicle.year}
                            on:select={(event) => {
                                $registerStore[REGISTER_CONFIG.STEPS.CAR.key].vehicle.year = event.detail;
                            }}
                            placeholder="Select Vehicle Year"
                            showChevron={true}
                            isClearable={false}
                            showIndicator={true}
                            isDisabled={isReviewStep()}
                        />
                    </div>
                </div>

                <div class="form__control">
                    <label
                        class="form__disclosure"
                        for="disclosures.transunion"
                        class:form__label--error={formErrorsMap['disclosures.transunion']}>Terms and Conditions</label
                    >
                    <br />
                    <div class="form__desc">
                        <p>
                            I/We certify that the information provided in this application is true and correct as of the
                            date of this application and acknowledge my/our understanding that any intentional or
                            negligent misrepresentation(s) of the information contained this application may result in
                            civil liability for monetary damages to Term Finance (SME TT) Limited, its agents,
                            successors and assigns, insurers and any other person who may suffer any loss due to
                            reliance upon any misrepresentation which I/we have made on this application.
                        </p>
                        <p>
                            As part of the loan application process, Term Finance (SME TT) is required to perform due
                            diligence on the business and owner(s)/directors. By checking this box, I/we consent and
                            authorise Term Finance (SME TT) Limited to obtain additional information on my/our credit
                            history, from any credit bureau, lenders or grantors of credit. Further, by checking this
                            box I understand that Term Finance may contact any stakeholder of the business and/or
                            owner(s)/directors including but not limited to suppliers, customers and employees in the
                            process of evaluating the credit worthiness of the business and/or owner(s)/directors.
                        </p>
                        <p>
                            I/We jointly and severally agree to indemnify Term Finance (SME TT) against any loss,
                            claims, damages, liabilities, actions and proceedings, legal and or other expense which may
                            be directly or reasonably incurred as a consequence of such disclosure on your part and of
                            any intentional or negligent misrepresentation made by me/us on this application.
                        </p>
                    </div>
                    <br />
                    <div class="form__select">
                        <Select
                            items={REGISTER_CONFIG.OPTIONS.YES_NO}
                            bind:value={$registerStore[REGISTER_CONFIG.STEPS.CAR.key].disclosures.transunion}
                            on:select={(event) => {
                                $registerStore[REGISTER_CONFIG.STEPS.CAR.key].disclosures.transunion = event.detail;
                            }}
                            placeholder="Select Yes/No"
                            showChevron={true}
                            isDisabled={isReviewStep()}
                        />
                    </div>
                </div>
            {/if}
        {/if}

        {#if step.key == REGISTER_CONFIG.STEPS.EMPLOYER.key || (step.key == REGISTER_CONFIG.STEPS.REVIEW.key && $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType === SHARED_CONFIG.LOAN_TYPES.PERSONAL)}
            {#if step.key == REGISTER_CONFIG.STEPS.REVIEW.key}
                <div class="form__title" on:keydown on:click={() => (review.employer = !review.employer)}>
                    <div>{REGISTER_CONFIG.STEPS.EMPLOYER.labels.title}</div>
                    <img
                        src={`/images/icon_caret_${review.employer ? 'up' : 'down'}.png`}
                        height="20"
                        alt="caret"
                        class="h-[20px]"
                    />
                </div>
            {/if}
            {#if step.key != REGISTER_CONFIG.STEPS.REVIEW.key || (step.key == REGISTER_CONFIG.STEPS.REVIEW.key && review.employer)}
                <div class="form__control">
                    <label for="employerName" class:form__label--error={formErrorsMap['name']}>Employer Name</label>
                    <input
                        name="employerName"
                        bind:value={$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].name}
                        disabled={isReviewStep()}
                    />
                </div>
                <div class="form__control">
                    <label
                        for="address"
                        class:form__label--error={formErrorsMap['address.city'] ||
                            formErrorsMap['address.street'] ||
                            formErrorsMap['address.country']}>Employer Address</label
                    >
                    <div class="stack-input">
                        <div class="stack-input__input">
                            <input
                                name="address.street"
                                placeholder="Street"
                                bind:value={$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].address.street}
                                disabled={isReviewStep()}
                            />
                        </div>
                        <div class="stack-input__input">
                            <input
                                name="address.city"
                                placeholder="City"
                                bind:value={$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].address.city}
                                disabled={isReviewStep()}
                            />
                        </div>
                        <div class="stack-input__input">
                            <div class="form__select">
                                <Select
                                    items={countryOptions}
                                    listPlacement="bottom"
                                    bind:value={$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].address.country}
                                    on:select={(event) => {
                                        $registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].address.country =
                                            event.detail;
                                    }}
                                    placeholder="Country"
                                    showChevron={true}
                                    isDisabled={$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].address.country !=
                                        null}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form__control">
                    <label for="occupation" class:form__label--error={formErrorsMap['occupation']}>Occupation</label>
                    <input
                        name="occupation"
                        bind:value={$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].occupation}
                        disabled={isReviewStep()}
                    />
                </div>
                {#if $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].salary}
                    <div class="form__control">
                        <label for="monthlyIncome">Income</label>
                        <div class="form__value">
                            {formatCurrency(
                                $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].salary,
                                SHARED_CONFIG.COUNTRIES[
                                    $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country.value
                                ].locale,
                                SHARED_CONFIG.COUNTRIES[
                                    $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country.value
                                ].currency,
                            )}
                        </div>
                    </div>
                {/if}
                <div class="form__control">
                    <label for="hireDate" class:form__label--error={formErrorsMap['hireDate']}>Date of Hire</label>
                    <DateSelector
                        date={$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].hireDate}
                        disabled={isReviewStep()}
                        on:change={({ detail }) => {
                            $registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].hireDate = detail.date;
                        }}
                    />
                </div>
                <div class="form__control">
                    <label for="payFrequency" class:form__label--error={formErrorsMap['income.frequency.type']}
                        >Pay Frequency</label
                    >
                    <div class="form__select">
                        <Select
                            items={REGISTER_CONFIG.OPTIONS.PAY_FREQUENCY}
                            listPlacement="bottom"
                            bind:value={$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].income.frequency.type}
                            on:select={(event) => {
                                $registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].income.frequency.type = event.detail;
                            }}
                            placeholder="Select Pay Frequency"
                            isClearable={false}
                            isDisabled={isReviewStep()}
                        />
                    </div>
                </div>
                {#if $registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].income.frequency.type && ($registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].income.frequency.type.value == 'Weekly' || $registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].income.frequency.type.value == 'BiWeekly')}
                    <div class="form__control">
                        <label for="payFrequencyDate">When is your next pay day?</label>
                        <DateSelector
                            date={$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].income.frequency.date}
                            disabled={isReviewStep()}
                            on:change={({ detail }) => {
                                $registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].income.frequency.date = detail.date;
                            }}
                        />
                    </div>
                {/if}
                {#if $registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].income.frequency.type && $registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].income.frequency.type.value == 'Monthly'}
                    <div class="form__control">
                        <label for="payFrequency">What day of the month do you get paid?</label>
                        <div class="form__select">
                            <Select
                                items={REGISTER_CONFIG.OPTIONS.DAY_OF_MONTH}
                                listPlacement="bottom"
                                bind:value={$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].income.frequency
                                    .dayOfMonth}
                                on:select={(event) => {
                                    $registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].income.frequency.dayOfMonth =
                                        event.detail;
                                }}
                                placeholder="Select Day of Week"
                                isClearable={false}
                                isDisabled={isReviewStep()}
                            />
                        </div>
                    </div>
                {/if}
                {#if $registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].income.frequency.type && $registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].income.frequency.type.value != 'TwicePerMonth'}
                    <div class="form__control">
                        <label
                            for="payFrequencyDay"
                            class:form__label--error={formErrorsMap['income.freqency.dayOfWeek']}
                            >What day of the week do you get paid?</label
                        >
                        <div class="form__select">
                            <Select
                                items={REGISTER_CONFIG.OPTIONS.DAY_OF_WEEK}
                                listPlacement="bottom"
                                bind:value={$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].income.frequency
                                    .dayOfWeek}
                                on:select={(event) => {
                                    $registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].income.frequency.dayOfWeek =
                                        event.detail;
                                }}
                                placeholder="Select Day of Week"
                                isClearable={false}
                                isDisabled={isReviewStep()}
                            />
                        </div>
                    </div>
                {/if}
                <div class="form__control">
                    <label
                        class="form__disclosure"
                        for="creditDisclosureEmployerContact"
                        class:form__label--error={formErrorsMap['disclosures.credit']}>Credit Disclosure</label
                    >
                    <div class="form__desc">
                        Do you agree to us contacting your employer to verify that your application details are true and
                        accurate in all respects?
                    </div>
                    <div class="form__select">
                        <Select
                            items={REGISTER_CONFIG.OPTIONS.YES_NO}
                            bind:value={$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].disclosures.credit}
                            on:select={(event) => {
                                $registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].disclosures.credit = event.detail;
                            }}
                            placeholder="Select Yes/No"
                            showChevron={true}
                            isDisabled={isReviewStep()}
                        />
                    </div>
                </div>
                <div class="form__control">
                    <label
                        class="form__disclosure"
                        for="creditDisclosurePersonalContact"
                        class:form__label--error={formErrorsMap['disclosures.loanStatus']}>Loan Status Disclosure</label
                    >
                    <div class="form__desc">
                        Do you agree to us sending you text message and/or email alerts to update you on the status of
                        your loan application?
                    </div>
                    <div class="form__select">
                        <Select
                            items={REGISTER_CONFIG.OPTIONS.YES_NO}
                            listPlacement="bottom"
                            bind:value={$registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].disclosures.loanStatus}
                            on:select={(event) => {
                                $registerStore[REGISTER_CONFIG.STEPS.EMPLOYER.key].disclosures.loanStatus =
                                    event.detail;
                            }}
                            placeholder="Select Yes/No"
                            showChevron={true}
                            isDisabled={isReviewStep()}
                        />
                    </div>
                </div>
            {/if}
        {/if}
        {#if step.key == REGISTER_CONFIG.STEPS.CREDENTIALS.key || step.key == REGISTER_CONFIG.STEPS.REVIEW.key}
            {#if step.key == REGISTER_CONFIG.STEPS.REVIEW.key}
                <div class="form__title" on:keydown on:click={() => (review.credentials = !review.credentials)}>
                    <div>{REGISTER_CONFIG.STEPS.CREDENTIALS.labels.title}</div>
                    <img
                        src={`/images/icon_caret_${review.credentials ? 'up' : 'down'}.png`}
                        height="20"
                        alt="caret"
                        class="h-[20px]"
                    />
                </div>
            {/if}
            {#if step.key != REGISTER_CONFIG.STEPS.REVIEW.key || (step.key == REGISTER_CONFIG.STEPS.REVIEW.key && review.credentials)}
                <div class="form__control">
                    <label for="username" class:form__label--error={formErrorsMap['username']}>Username</label>
                    <input
                        disabled={step.key == REGISTER_CONFIG.STEPS.REVIEW.key}
                        name="username"
                        bind:value={$registerStore[REGISTER_CONFIG.STEPS.CREDENTIALS.key].username}
                    />
                </div>
                <div class="form__control">
                    <label for="password" class:form__label--error={formErrorsMap['password']}>Password</label>
                    <div class="split-input">
                        {#if isPasswordRevealed}
                            <input
                                name="password"
                                type="text"
                                bind:value={$registerStore[REGISTER_CONFIG.STEPS.CREDENTIALS.key].password}
                                disabled={step.key == REGISTER_CONFIG.STEPS.REVIEW.key}
                            />
                        {:else}
                            <input
                                name="password"
                                type="password"
                                bind:value={$registerStore[REGISTER_CONFIG.STEPS.CREDENTIALS.key].password}
                                disabled={step.key == REGISTER_CONFIG.STEPS.REVIEW.key}
                            />
                        {/if}
                        <img
                            on:keydown
                            class="reveal-icon h-[30px]"
                            alt="reveal"
                            on:click={() => (isPasswordRevealed = !isPasswordRevealed)}
                            src={`/images/icon_reveal_${isPasswordRevealed ? 'hide' : 'show'}.png`}
                            height="30"
                        />
                    </div>
                </div>
                <div class="form__control">
                    <label for="confirmPassword" class:form__label--error={formErrorsMap['repeatPassword']}
                        >Repeat Password</label
                    >
                    <div class="split-input">
                        {#if isPasswordRevealed}
                            <input
                                name="repeatPassword"
                                type="text"
                                bind:value={$registerStore[REGISTER_CONFIG.STEPS.CREDENTIALS.key].repeatPassword}
                                disabled={step.key == REGISTER_CONFIG.STEPS.REVIEW.key}
                            />
                        {:else}
                            <input
                                name="repeatPassword"
                                type="password"
                                bind:value={$registerStore[REGISTER_CONFIG.STEPS.CREDENTIALS.key].repeatPassword}
                                disabled={step.key == REGISTER_CONFIG.STEPS.REVIEW.key}
                            />
                        {/if}
                        <img
                            on:keydown
                            class="reveal-icon h-[30px]"
                            alt="reveal"
                            on:click={() => (isPasswordRevealed = !isPasswordRevealed)}
                            src={`/images/icon_reveal_${isPasswordRevealed ? 'hide' : 'show'}.png`}
                            height="30"
                        />
                    </div>
                </div>
            {/if}
        {/if}
        {#if step.key == REGISTER_CONFIG.STEPS.COMPLETE.key}
            <div class="form__title form__title--big">
                Hey,
                {$registerStore[REGISTER_CONFIG.STEPS.DETAILS.key].firstName}! Thank you for submitting your loan
                application to Term Finance.
            </div>
            <div class="form__control form__control--padded">
                <div class="form__title">Next Steps?</div>
                <div class="form__subtitle">
                    {#if $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType == SHARED_CONFIG.LOAN_TYPES.CAR}
                        <p>As part of the loan process, we require a few documents to move forward.</p>
                        <p>Please see list below:</p>
                        <ul>
                            <li>1.Valid Driver's permit</li>
                            <li>2. Certified copy for the vehicle</li>
                            <li>3. Proof income (e.g: payslip / job letter)</li>
                            <li>4. Certified copy for the vehicle</li>
                            <li>5. Copy of insurance policy</li>
                        </ul>
                        <p>
                            If you have any questions or require any clarification, you can contact us at any time at
                            <a href="mailto:tfauto@mytermfinance.com">tfauto@mytermfinance.com</a>
                            or
                            <a href="tel:868-778-3763">868-778-3763</a>
                            (Khadija) or
                            <a href="tel:868-467-4831">868-467-4831</a>
                            (Keisha).
                        </p>
                        <p>
                            On behalf of the Term Finance Auto team, we hope you have a great day and thank you for
                            taking time to apply to Term Finance Auto.
                        </p>
                    {:else}
                        <p>
                            We'll need a scan or photo of your most recent payslip, a form of ID (passport, drivers
                            license or national identification card) and a proof of address (utility bill or bank
                            statement). You can login and upload these now.
                        </p>
                    {/if}
                </div>
                <div class="form__control form__control--actions">
                    <div class="form__action">
                        <Button
                            secondary
                            onClick={() => {
                                dispatch('reset');
                            }}
                        >
                            Return to Home Screen
                        </Button>
                    </div>
                    <div class="form__action">
                        <Button
                            primary
                            onClick={() => {
                                // window.open('https://yourtermfinance.com', '_blank');
                                push('/login');
                            }}
                        >
                            Login Now
                        </Button>
                    </div>
                </div>
            </div>
            <div class="form__control form__control--padded">
                <div class="form__title">Questions?</div>
                <div class="form__subtitle">
                    <p>If you have any follow-up questions regarding your application please get in touch with us.</p>
                    <ul>
                        <li>Trinidad & Tobago: (868)-235-5650</li>
                        <li>Barbados: (246)-428-TERM (8376)</li>
                        <li>Guyana: (592)-608-TERM (8376)</li>
                        <li>Jamaica: (876)-891-TERM (8376)</li>
                        <li>Lucia: (758)-484-TERM (8376)</li>
                    </ul>
                </div>
            </div>
        {/if}
        {#if step.key != REGISTER_CONFIG.STEPS.COMPLETE.key}
            <div class="form__control form__control--actions">
                <div class="form__action">
                    <Button
                        secondary
                        disabled={step.key === REGISTER_CONFIG.STEPS.ESSENTIALS.key}
                        onClick={handlePrevious}
                    >
                        Previous
                    </Button>
                </div>
                <div class="form__action">
                    <Button primary onClick={handleNext} type="submit">
                        {step.key == REGISTER_CONFIG.STEPS.REVIEW.key ? 'Submit Application' : 'Next'}
                    </Button>
                </div>
                {#if canReviewApplication && step.key != REGISTER_CONFIG.STEPS.REVIEW.key}
                    <div class="form__action">
                        <Button onClick={() => handleJump(REGISTER_CONFIG.STEPS.REVIEW)}>
                            Review & Submit Application
                        </Button>
                    </div>
                {/if}
            </div>
        {/if}
    </form>
</div>

<style lang="scss" type="text/scss">
    @use 'src/sass/base' as *;
    @use 'src/sass/tailwind';

    form {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    input {
        width: 100%;
        outline: 0;
        background: $color-white;
        border: 1px solid $color-gray-dark;
        border-radius: 8px;
        padding: 10px 10px;
        font-size: em(16);
        color: $color-black;
        font-weight: $fw-regular;
        letter-spacing: 0.3px;
        transition: 0.25s all ease;

        &:focus {
            border-color: $color-primary;
        }

        &:hover {
            border-color: $color-primary;
        }

        &:disabled {
            background: $color-gray-light;
        }

        &::placeholder {
            font-weight: $fw-regular;
            color: $color-gray-dark;
        }
    }

    label {
        display: block;
        margin: em(10) 0;
        font-weight: $fw-regular;
        font-size: em(16);
        color: $color-black;
    }

    textarea {
        width: 100%;
        resize: none;
        height: 200px;
        padding: em(25);
        color: $color-gray-text;
        font-size: em(14);
        line-height: 1.25;
        outline: none;

        &:focus {
            border-color: $color-primary;
        }
    }

    .root {
        width: 100%;
    }

    .form {
        &__title {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding-bottom: em(10);
            margin: em(10) 0;
            color: $color-primary;
            font-size: em(24);
            font-weight: $fw-light;
            border-bottom: 1px solid $color-gray;

            &:hover {
                color: darken($color-primary, 10%);
            }

            &--big {
                padding-bottom: 0;
                border-bottom: none;
                font-size: em(36);
                line-height: 1.2;
                color: $color-black;
            }
        }

        &__subtitle {
            margin: em(20) 0;
            line-height: 1.25;

            p {
                margin: em(25) 0;
            }

            ul {
                margin: em(25);
                list-style: disc;

                li {
                    margin: em(5) 0;
                }
            }
        }

        &__control {
            margin: em(40) 0;

            &--padded {
                margin: em(75) 0;
            }

            &--actions {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin: em(80) 0 em(40) 0;
            }
        }

        &__label {
            &--error {
                font-weight: $fw-medium !important;
                color: $color-red !important;
            }
        }

        &__disclaimer {
            margin: em(40) 0;
            font-size: em(16);
            line-height: 1.25;
            color: $color-gray-text;

            &:before {
                content: '* ';
                color: $color-primary;
            }
        }

        &__action {
            margin-left: em(10);
        }

        &__select {
            width: 100%;
            font-size: em(16);
            font-weight: $fw-regular;
            --inputPadding: 12px 10px;
            --padding: 12px 10px;
            --border: 1px solid #c7c7ca;
            --borderFocusColor: #662cc8;
            --borderRadius: 8px;
            --itemHoverBG: #f4f4f4;
            --itemIsActiveBG: #662cc8;
            --multiItemActiveBG: #662cc8;
            --placeholderColor: #c7c7ca;
            --multiClearBG: #999999;
            --disabledBackground: #fbfbfb;
            --disabledBorderColor: #c7c7ca;
            --disabledColor: #000;
        }

        &__desc {
            margin: em(-5) 0 em(10) 0;
            font-size: em(14);
            line-height: 1.25;
            color: $color-gray-dark;

            p {
                margin-bottom: em(15);
            }
        }

        &__value {
            font-size: em(18);
            font-weight: $fw-medium;
        }
    }

    .form-errors {
        padding: em(20);
        margin-top: em(25);
        font-weight: $fw-regular;
        line-height: 1.3;
        background: rgba($color-red, 0.1);
        border: 1px solid $color-red;
        border-radius: 5px;
        font-family: $ff-primary;

        &__header {
            margin-bottom: em(15);
            font-size: em(20);
            line-height: 24px;
            font-weight: $fw-regular;
            color: $color-red;
        }

        &__error {
            font-size: em(16);
            font-weight: $fw-medium;
            color: $color-red;
        }
    }

    .split-input {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        input {
            margin-right: em(15);
        }
    }

    .reveal-icon {
        cursor: pointer;
        opacity: 0.25;
        transition: all 0.25s ease-in;

        &:hover {
            opacity: 1;
        }
    }

    .stack-input {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;

        &__input {
            width: 100%;
            margin: em(5) 0;
        }
    }

    .click-select-option {
        cursor: pointer;
        margin: em(10) 0;
        padding: em(25);
        border: 1px solid $color-gray-dark;
        border-radius: 5px;
        transition: all 0.15s ease-in;

        &:hover {
            background: $color-secondary;
            box-shadow:
                0 5px 10px rgba(0, 0, 0, 0.19),
                0 6px 6px rgba(0, 0, 0, 0.15);
        }

        &--selected {
            background: $color-secondary;
            border: 1px solid $color-primary;
            box-shadow:
                0 5px 10px rgba(0, 0, 0, 0.19),
                0 6px 6px rgba(0, 0, 0, 0.15);
            opacity: 1 !important;
        }

        &--disabled {
            pointer-events: none;
            opacity: 0.25;
            cursor: disabled;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__title {
            margin-bottom: em(8);
            font-size: em(20);
            font-weight: $fw-regular;
        }

        &__subtitle {
            font-size: em(12);
            font-weight: $fw-light;
            text-transform: uppercase;
            letter-spacing: 2px;
            color: $color-primary;
        }

        &__desc {
            margin-top: em(15);
            font-size: em(14);
            color: $color-gray-text;

            p {
                margin: em(15) 0;
            }
        }

        &__icon {
            width: 75px;
            height: 75px;
        }
    }

    .review-header {
        margin-bottom: em(50);

        &__desc {
            color: $color-gray-text;
            font-size: em(14);
            line-height: 1.5;
        }
    }

    .slider {
        margin: em(40) 0 em(40) 0;
        --range-handle: #662cc8;
        --range-handle-inactive: #662cc8;
        --range-handle-focus: #662cc8;
        --range-range: #662cc8;
        --range-range-inactive: #662cc8;
    }
</style>
