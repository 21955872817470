import { getAnalytics, logEvent } from 'firebase/analytics';

export const EVENTS = {
    APPLICATION_STARTED: 'application_started',
    APPLICATION_COMPLETED: 'application_completed',
    CUSTOMER_CREATED: 'customer_created',
    CUSTOMER_WITHOUT_LOAN: 'customer_without_loan',
    FOOTBALL_REGISTER: 'football_register',
};

export function track(eventName, data) {
    if ('<@ENV@>' == 'PRODUCTION') {
        const analytics = getAnalytics();
        logEvent(analytics, eventName, data);
    } else {
        console.debug(`Simulate tracking event ${eventName}`, data);
    }
}

export default {
    EVENTS,
    track,
};
