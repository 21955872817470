<script>
    import { getContext, createEventDispatcher } from 'svelte';
    import { SupportModal, supportModalOptions } from 'components/SupportModal';

    const dispatch = createEventDispatcher();

    const { open } = getContext('simple-modal');

    let showMobileNavOverlay = false;
</script>

<nav class="fixed left-0 top-0 z-[100] flex w-full items-center justify-between bg-[#5426AD] px-3 py-3 shadow-lg">
    <div class="flex w-full items-center gap-x-6 text-white">
        <img class="block h-[65px]" src={`/images/nav_logo_white@2x.png`} height="60" alt="logo" />
        <a href="https://mytermfinance.com/#/register" target="_blank" class="hidden md:block">Loans</a>
        <a href="https://mytfshop.com" target="_blank" class="hidden md:block">Shop</a>
        <a href="https://wellness.mytermfinance.com" target="_blank" class="hidden md:block">Wellness</a>
        <a href="https://mytermfinance.com/#/register?country=tt&type=3" target="_blank" class="hidden md:block">Auto</a
        >
        <div
            class="hidden cursor-pointer lg:block"
            on:keypress
            on:click={() => {
                dispatch('football', {});
            }}
        >
            Football
        </div>
        <div
            class="hidden cursor-pointer lg:block"
            on:keypress
            on:click={() => {
                open(SupportModal, {}, supportModalOptions);
            }}
        >
            Contact us
        </div>
        <button
            class="hidden flex-shrink-0 rounded-full bg-white px-4 py-2 text-sm font-semibold text-[#5426AD] transition-all hover:bg-[#F5F4FF] sm:block"
            on:click={() => {
                dispatch('login', {});
            }}
        >
            Log in
        </button>
    </div>
    <div class="flex w-1/2 items-center justify-end gap-x-3">
        <img class="block h-[30px] grayscale md:h-[40px]" src={`/images/nav_logo_myfi.png`} height="60" alt="logo" />
        <img
            class="block h-[20px] grayscale md:h-[30px]"
            src={`/images/nav_logo_fc_white@2x.png`}
            height="60"
            alt="logo"
        />
        <img class="block h-[20px] md:h-[30px]" src={`/images/nav_logo_axcel.png`} height="60" alt="logo" />
        <div
            class="inline-block cursor-pointer md:hidden"
            on:keypress
            on:click={() => {
                showMobileNavOverlay = !showMobileNavOverlay;
            }}
        >
            <i
                class="fa-solid fa-bars fa-xl text-white"
                class:fa-bars={!showMobileNavOverlay}
                class:fa-x={showMobileNavOverlay}
            ></i>
        </div>
    </div>
</nav>

{#if showMobileNavOverlay}
    <div
        class:pointer-events-none={!showMobileNavOverlay}
        class="animate__animated animate__faster absolute left-0 top-0 z-50 flex h-screen w-screen flex-col items-center justify-center gap-y-6 bg-[#5426AD] text-3xl text-white"
        class:animate__fadeIn={showMobileNavOverlay}
    >
        <a href="https://mytermfinance.com/#/register" target="_blank">Loans</a>
        <a href="https://mytfshop.com" target="_blank">Shop</a>
        <a href="https://wellness.mytermfinance.com" target="_blank">Wellness</a>
        <div
            class="cursor-pointer"
            on:keypress
            on:click={() => {
                showMobileNavOverlay = false;
                dispatch('football', {});
            }}
        >
            Football
        </div>
        <div
            class="cursor-pointer"
            on:keypress
            on:click={() => {
                open(SupportModal, {}, supportModalOptions);
            }}
        >
            Contact us
        </div>
        <button
            class="flex-shrink-0 rounded-full rounded-full bg-white px-12 py-4 text-xl font-semibold text-[#5426AD] transition-all hover:bg-[#F5F4FF]"
            on:click={() => {
                showMobileNavOverlay = false;
                dispatch('login', {});
            }}
        >
            Log in
        </button>
    </div>
{/if}

<style lang="scss">
    @use 'src/sass/base' as *;
    @use 'src/sass/tailwind';
</style>
