import { get } from 'svelte/store';
import { push } from 'svelte-spa-router';
import { wrap } from 'svelte-spa-router/wrap';

import authStore from 'stores/auth';

import RegisterPage from 'pages/RegisterPage';
import LoginPage from 'pages/LoginPage';
import LoansPage from 'pages/LoansPage';
import DocsPage from 'pages/DocsPage';
import ProfilePage from 'pages/ProfilePage';

function isAuthenticated() {
    let _authStore = get(authStore);
    return _authStore && _authStore.customerId != null;
}

let routes = new Map();
let conditions = {
    userData: {
        onConditionsFailed: () => {
            push('/');
        },
    },
    conditions: [
        (detail) => {
            return isAuthenticated();
        },
    ],
};

routes.set(
    '/',
    wrap({
        component: LoginPage,
    }),
);

routes.set(
    '/login',
    wrap({
        component: LoginPage,
    }),
);

routes.set(
    '/football',
    wrap({
        component: LoginPage,
    }),
);

routes.set(
    '/reset-password/:token',
    wrap({
        component: LoginPage,
    }),
);

routes.set(
    '/register',
    wrap({
        component: RegisterPage,
    }),
);

routes.set(
    '/loans',
    wrap({
        component: LoansPage,
        ...conditions,
    }),
);

routes.set(
    '/docs',
    wrap({
        component: DocsPage,
        ...conditions,
    }),
);

routes.set(
    '/profile',
    wrap({
        component: ProfilePage,
        ...conditions,
    }),
);

export default routes;
